var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[(_vm.loading)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1)]):[_c('div',{staticClass:"card-body p-sm-3"},[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":_vm.msg.type,"dismissible":"","show":_vm.msg.text},model:{value:(_vm.msg.has),callback:function ($$v) {_vm.$set(_vm.msg, "has", $$v)},expression:"msg.has"}},[_vm._v(_vm._s(_vm.msg.text))]),_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group mb-2"},[_c('label',{staticClass:"required",attrs:{"for":"login"}},[_vm._v(_vm._s(_vm.$t("form.name")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:[
              {
                'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
              },
              _vm.type === 'view' ? 'form-control-plaintext' : 'form-control',
            ],attrs:{"id":"login","placeholder":_vm.$t('form.name-placeholder'),"readonly":_vm.type === 'view'},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t("form.name-req")))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group mb-2"},[_c('label',{attrs:{"for":"phone"}},[_vm._v(_vm._s(_vm.$t("form.phone")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],staticClass:"form-control",class:[
              _vm.type === 'view' ? 'form-control-plaintext' : 'form-control',
            ],attrs:{"id":"phone","placeholder":_vm.type === 'view' && !_vm.form.phone
                ? ``
                : `${_vm.$t('form.phone-placeholder')}`,"readonly":_vm.type === 'view'},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phone", $event.target.value)}}})]),_c('div',{staticClass:"form-group mb-2"},[_c('label',{attrs:{"for":"city"}},[_vm._v(_vm._s(_vm.$t("form.city")))]),(_vm.type === 'view')?_c('div',{staticClass:"input-group input-group-merge"},[(_vm.form.city && _vm.form.city.name)?_c('input',{staticClass:"form-control form-control-plaintext",attrs:{"readonly":""},domProps:{"value":_vm.form.city.name}}):_c('input',{staticClass:"form-control form-control-plaintext",attrs:{"value":"","readonly":""}})]):_c('div',{staticClass:"input-group input-group-merge"},[_c('multiselect',{attrs:{"options":_vm.cities,"track-by":"id","label":"name","multiple":false,"placeholder":_vm.$t('form.city-select')},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})],1)]),_c('div',{staticClass:"form-group mb-2"},[_c('label',{attrs:{"for":"address"}},[_vm._v(_vm._s(_vm.$t("form.address")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.address),expression:"form.address"}],staticClass:"form-control",class:[
              _vm.type === 'view' ? 'form-control-plaintext' : 'form-control',
            ],attrs:{"id":"address","placeholder":_vm.type === 'view' && !_vm.form.address
                ? ``
                : `${_vm.$t('form.address-placeholder')}`,"readonly":_vm.type === 'view'},domProps:{"value":(_vm.form.address)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "address", $event.target.value)}}})]),_c('div',{staticClass:"form-group mb-2"},[_c('label',{attrs:{"for":"descr"}},[_vm._v(_vm._s(_vm.$t("form.descr")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.descr),expression:"form.descr"}],staticClass:"form-control",class:[
              _vm.type === 'view' ? 'form-control-plaintext' : 'form-control',
            ],attrs:{"id":"descr","placeholder":_vm.type === 'view' && !_vm.form.descr
                ? ``
                : `${_vm.$t('form.descr-placeholder')}`,"readonly":_vm.type === 'view'},domProps:{"value":(_vm.form.descr)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "descr", $event.target.value)}}})]),(_vm.type === 'create')?_c('div',{staticClass:"mt-4 text-center text-lg-right"},[_c('div',{staticClass:"form-group mb-0"},[_c('router-link',{staticClass:"btn btn-warning mx-2 mb-2",attrs:{"to":"/companies"}},[_vm._v(" "+_vm._s(_vm.$t("btn.cancel"))+" ")]),_c('button',{staticClass:"btn btn-primary mx-2 mb-2 mr-lg-0",attrs:{"disabled":_vm.inprogress,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("company.add"))+" ")])],1)]):_vm._e(),(_vm.type === 'edit')?_c('div',{staticClass:"mt-4 text-center text-lg-right"},[_c('div',{staticClass:"form-group mb-0"},[_c('router-link',{staticClass:"btn btn-warning mx-2 mb-2",attrs:{"to":"/companies"}},[_vm._v(" "+_vm._s(_vm.$t("btn.cancel"))+" ")]),_c('button',{staticClass:"btn btn-primary mx-2 mb-2 mr-lg-0",attrs:{"disabled":_vm.inprogress,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("btn.save"))+" ")])],1)]):_vm._e(),(_vm.type === 'view')?_c('div',{staticClass:"mt-4 text-center text-lg-right"},[_c('div',{staticClass:"form-group mb-0"},[_c('router-link',{staticClass:"btn btn-warning mx-2 mb-2",attrs:{"to":"/companies/"}},[_vm._v(" "+_vm._s(_vm.$t("btn.back"))+" ")]),_c('router-link',{staticClass:"btn btn-primary mx-2 mb-2 mr-lg-0",attrs:{"to":`/companies/edit/${_vm.$route.params.id}`}},[_vm._v(_vm._s(_vm.$t("btn.edit")))])],1)]):_vm._e()])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }